import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

class areachart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            options: {
                colors: ["#ccc", "#3c4ccf", "#02a499"],
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                    width: 0.1,
                },
                grid: {
                    borderColor: "#f8f8fa",
                    row: {
                        colors: ["transparent", "transparent"],
                        opacity: 0.5,
                    },
                },
                xaxis: {
                    categories: [
                        "Premium Visa",
                        "GoAML",
                        "Incorporate Domestic Company",
                        "CRS-NIL",
                        "FATCA-NIL",
                        "Work Permit",
                    ],
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                legend: {
                    show: false,
                },
            },
            series: [
                { name: "Approved", data: [] },
                { name: "Pending", data: [] },
                { name: "Rejected", data: [] },
            ],
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            const services = Object.keys(this.props.data)
            const approvedData = services.map(
                service => this.props.data[service]?.Approved || 0
            )
            const pendingData = services.map(
                service => this.props.data[service]?.Pending || 0
            )
            const rejectedData = services.map(
                service => this.props.data[service]?.Rejected || 0
            )

            this.setState({
                series: [
                    { name: "Approved", data: approvedData },
                    { name: "Pending", data: pendingData },
                    { name: "Rejected", data: rejectedData },
                ],
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height="290"
                />
            </React.Fragment>
        )
    }
}

export default areachart
