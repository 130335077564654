
import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")

const FatcaAccountDetail = () => {
    const { accountID } = useParams()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true) // New loading state
    const token = localStorage.getItem("Admintoken")
    const navigate = useNavigate();
    const apiURL = process.env.REACT_APP_BACKEND_URL;

    document.title = "Service Details | Admin & Dashboard"

    const fetchVisaDetails = async () => {
        try {
            setLoading(true)
            const response = await fetch(
                `${apiURL}/api/admin/fatca/account/details/${accountID}`,
                {
                    method: "GET", // GET is the default method, but it's good practice to specify it explicitly
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const res = await response.json()
            console.log("res premium visa details", res)
            if (res) {
                if (res.message == "Token verification failed") {
                    navigate("/login")
                }
                console.log("res premium visa details", res.data)
                setData(res.data)
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false) // Set loading to false after fetching
        }
    }

    useEffect(() => {
        fetchVisaDetails()
    }, [])

    console.log("company details Data", data)

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg={12}>
                            <Card style={{ padding: "20px", marginTop: "20px" }}>
                                <p style={{ fontSize: "24px", fontWeight: "500" }}>
                                    FATCA Account Report Service Details
                                </p>
                                {data ? <>
                                    <Row
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            {" "}
                                            <strong> GIIN : </strong>{" "}
                                            <span style={{ textAlign: "end" }}>
                                                {data?.GIIN}{" "}
                                            </span>
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            {" "}
                                            <strong>Application Status By OnlineKorp : </strong>{" "}
                                            {data?.active}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong>Application Form Status :</strong> {data?.status}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong>Financial Institution :</strong>{" "}
                                            {data?.financialInstitution ? data?.financialInstitution : "-"}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong>Filer Category :</strong>{" "}
                                            {data?.filerCategory ? data?.filerCategory : "-"}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong>Receiving Country :</strong>{" "}
                                            {data?.receivingCountry ? data?.receivingCountry : "-"}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong> Applied Date : </strong>
                                            {data?.createdAt?.substring(0, 10)}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong>Transmitting Country :</strong>{" "}
                                            {data?.transmittingCountry ? data?.transmittingCountry : "-"}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong> Reporting Period : </strong>
                                            {data?.reportingPeriod?.substring(0, 10)}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong> XML Report Downloaded : </strong>
                                            {data?.status === "Incomplete" ? "No" : "YES"}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong>Tax Residency :</strong>{" "}
                                            {data?.taxResidency ? data?.taxResidency : "-"}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong> country : </strong>
                                            {data?.country}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong>city :</strong>{" "}
                                            {data?.city ? data?.city : "-"}
                                        </Col>
                                        <Col lg={6} style={{ fontSize: "16px", marginTop: "20px" }}>
                                            <strong> Address : </strong>
                                            {data?.address}
                                        </Col>
                                    </Row>
                                </> :
                                    <div>
                                        <p
                                            colSpan="6"
                                            style={{ textAlign: "center", padding: "20px" }}
                                        >
                                            Applicant Details Not Available.
                                        </p>
                                    </div>
                                }

                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default FatcaAccountDetail;
